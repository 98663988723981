import i18n from 'i18next';
import Backend from "i18next-xhr-backend"
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from "react-i18next"
import { I18N_CODES } from 'gatsby-env-variables'

let languageResources = {}
I18N_CODES.forEach(iso => {
    languageResources[iso] = {
        translation: require(`../translations/`+iso+`/translation.json`),
        about: require(`../translations/`+iso+`/about.json`)
    }
})

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        preload: I18N_CODES,
        resources: languageResources,
        ns: ['translation', 'about'],
        load: 'languageOnly',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        react: {
            useSuspense: false,
            wait: true,
        },

    });


export default i18n;