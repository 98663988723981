import React from 'react';
import FuseHighlight from './FuseHighlight';
import { useFuse } from './useFuse';
import { SearchIcon, XIcon } from '@heroicons/react/solid';
import { Transition } from '@headlessui/react'
import {COUNTRY_ID} from 'gatsby-env-variables'
const siteIndex = require(`../../../static/data/site_index_${COUNTRY_ID}.json`)

const Search = () => {

    const { hits, query, onSearch, setQuery } = useFuse(siteIndex, {
        matchAllOnEmptyQuery: false,
        shouldSort: true,
        includeMatches: true,
        threshold: 0.3, // At what point does the match algorithm give up. A threshold of 0.0 requires a perfect match (of both letters and location), a threshold of 1.0 would match anything.
        location: 0, // Determines approximately where in the text is the pattern expected to be found
        distance: 50, // Determines how close the match must be to the fuzzy location (specified by location). An exact letter match which is distance characters away from the fuzzy location would score as a complete mismatch. A distance of 0 requires the match be at the exact location specified. A distance of 1000 would require a perfect match to be within 800 characters of the location to be found using a threshold of 0.8
        maxPatternLength: 12,
        minMatchCharLength: 2,
        keys: ["title", "title_vernacular", "id"]
    });

    return (
<div>

<label htmlFor="search" className="sr-only">Search</label>
<div className="relative">
    <div className="absolute z-40 inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </div>
    <input id="search" 
        className="relative z-30 block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white focus:border-white sm:text-sm" 
        placeholder="Search" 
        type="search" 
        autoComplete="off" 
        onKeyUp={onSearch} 
        onChange={onSearch}
        name="search" />
    </div>

<Transition.Root show={(query.length !== 0)} as="div">
<div className="fixed z-20 inset-0 overflow-y-auto">
        <div className="flex justify-center pt-4 px-4 pb-20 mt-10 text-center sm:block sm:p-0">
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          ></Transition.Child>


            <div className="inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" onClick={() => setQuery('')} />
                </button>
              </div>
              <div className="sm:flex sm:items-start">

              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <ol>
                    {hits.slice(0,20).map(hit => (
                        <li key={hit.refIndex}>
                            <a href={`/${hit.item.type}/${hit.item.id}`}>{hit.item.tt} | {hit.item.tv} <b>{hit.item.type}</b></a>
                        </li>
                    ))}
                </ol>
              </div>
            </div>
        </div>
        </div>
        <div className="fixed z-10 inset-0 bg-black opacity-30"></div>
    </Transition.Root>

</div>);
};

export default Search