import * as React from "react"
import PropTypes from "prop-types"
import Header from "./header";
import {COLOR_PRIMARY,COLOR_SECONDARY} from 'gatsby-env-variables'
import '../styles/global.css';
import './i18n'
import { withTrans } from './i18n'


const Layout = ({ children, pageContext, location }) => {
    return (
        <div className="min-h-screen bg-gray-100">
            <div className={`bg-gradient-to-r from-primary-400 to-secondary-500 pb-32`}>
                <Header />
            </div>

            <main className="-mt-32">
                <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
                    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
                            {children}
                    </div>
                </div>
            </main>
        </div>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)