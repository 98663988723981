import React, {Fragment, useState } from "react"
import {Disclosure, Menu, Transition} from "@headlessui/react";
import {MenuIcon, TranslateIcon, XIcon} from "@heroicons/react/outline";
import {useTranslation, withTranslation} from 'react-i18next'
import { I18N_CODES, TITLE, COLOR_PRIMARY, COLOR_SECONDARY } from "gatsby-env-variables"
import Search from "../components/search/search"
const navigation = ['Languages', 'Study Bible', 'About']

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Header = (props) => {

    const { t, i18n } = useTranslation()
    const [values, setValues] = useState({
        language: 'en'
    });

    let languages = []
    I18N_CODES.forEach(iso => {
        languages.push({iso: iso, name: t(`languages.translations.`+ iso)})
    })

    function changei18n(event) {
        i18n.changeLanguage(event.target.dataset.iso)
        setValues(oldValues => ({...oldValues, [event.target.name]: event.target.value,}));
    }

  return (
      <Disclosure as="nav" className={`bg-gradient-to-r from-primary-400 to-secondary-500 border-b border-gray-300 border-opacity-25 lg:border-none`}>
          {({ open }) => (
              <>
                  <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                      <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-gray-400 lg:border-opacity-25">
                          <div className="px-2 flex items-center lg:px-0">
                              <a href='/' className="flex-shrink-0 text-white text-lg font-bold">
                                  { TITLE }
                              </a>
                              <div className="hidden lg:block lg:ml-10">
                                  <div className="flex space-x-4">
                                    <a className="text-white hover:bg-gray-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                                       href="/languages">{t('languages.title')}</a>
                                      <a className="text-white hover:bg-gray-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                                         href="http://inscript.org">{t('bibles.types.study')}</a>
                                      <a className="text-white hover:bg-gray-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                                         href="/about">{t('about.title')}</a>
                                  </div>
                              </div>
                          </div>
                          <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                              <div className="max-w-lg w-full lg:max-w-xs">
                                  {/* <Search /> */}
                              </div>
                          </div>
                          <div className="flex lg:hidden">
                              {/* Mobile menu button */}
                              <Disclosure.Button className="bg-gray-100 p-2 rounded-md inline-flex items-center justify-center text-gray-600 hover:text-white hover:bg-gray-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white">
                                  <span className="sr-only">Open main menu</span>
                                  {open ? (
                                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                                  ) : (
                                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                  )}
                              </Disclosure.Button>
                          </div>
                          <div className="hidden lg:block lg:ml-4">
                              <div className="flex items-center">
                                  <Menu as="div" className="ml-3 relative flex-shrink-0">
                                      <div>
                                          <Menu.Button className="rounded-full flex text-sm text-white focus:text-gray-600">
                                              <span className="sr-only">Open user menu</span>
                                              <TranslateIcon className="h-6 w-6" aria-hidden="true" />
                                          </Menu.Button>
                                      </div>
                                      <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                      >
                                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
                                              {languages.map((lng) => (
                                                  <Menu.Item key={lng.iso}>
                                                      {({ active }) => (
                                                          <span
                                                              data-iso={lng.iso}
                                                              onClick={changei18n}
                                                              className={classNames(
                                                              active ? 'bg-gray-100' : '',
                                                              'block py-2 px-4 text-sm text-gray-700'
                                                          )}>{lng.name}</span>
                                                      )}
                                                  </Menu.Item>
                                              ))}
                                          </Menu.Items>
                                      </Transition>
                                  </Menu>
                              </div>
                          </div>
                      </div>
                  </div>

                  <Disclosure.Panel className="lg:hidden">
                      <div className="px-2 pt-2 pb-3 space-y-1">
                        <a className="text-white block rounded-md py-2 px-3 text-base font-medium" href="/languages">{t('languages.title')}</a>
                        <a className="text-white block rounded-md py-2 px-3 text-base font-medium" href="http://inscript.org">{t('bibles.types.study')}</a>
                        <a className="text-white block rounded-md py-2 px-3 text-base font-medium" href="/about">{t('about.title')}</a>
                      </div>
                      <div className="pt-4 pb-3 border-t">
                          <div className="mt-3 px-2 space-y-1">
                              {languages.map((lng) => (
                                  <div key={lng.iso}>
                                      <a className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-gray-500 hover:bg-opacity-75">
                                          {lng.name}
                                      </a>
                                  </div>
                              ))}
                          </div>
                      </div>
                  </Disclosure.Panel>
              </>
          )}
      </Disclosure>
  )
}

export default withTranslation()(Header)